import React from "react";

import { default as _get } from "lodash/get";

import { GatsbySeo } from "gatsby-plugin-next-seo";

import ContentfulLocationFinder from "../contentful/ContentfulLocationFinder";
import ContentfulBreadcrumbs from "../contentful/ContentfulBreadcrumbs";

import { getUrl } from "../helper/uri";

export const Pharmacies = props => {
  const breadcrumbItems = [
    {
      name: "Apotheken-Finder",
      item: getUrl("apotheken"),
    },
  ];

  return (
    <article>
      <GatsbySeo
        title="Apotheken Finder zur Neurodermitis Beratung | Hautstark"
        description="Finde heraus, welche Partner-Apotheken in deiner Nähe sind und lass dich zum Thema Neurodermitis beraten! Haustark: Gemeinsam stark bei Neurodermitis"
      />

      <ContentfulBreadcrumbs items={breadcrumbItems} />

      <ContentfulLocationFinder
        content={_get(
          props,
          "pageContext.content.data.contentfulComponentLocationFinder",
          undefined
        )}
        locations={_get(props, "pageContext.locations", [])}
      />
    </article>
  );
};

export default Pharmacies;
